import { Cookies } from 'react-cookie';
import { COMMON_LOGIN_URL, COOKIE_KEY, LOGIN_APP_ID } from "../constants/EnvConstants";

export const redirectToCommonLogin = () => {
    window.location.href = COMMON_LOGIN_URL + (isLocalhost() ? "local_3001" : LOGIN_APP_ID);
};


export const isLocalhost = (): boolean => {
    return (
        window.location.href.includes("localhost") ||
        window.location.href.includes("127.0.0.1")
    );
};

export const loginForLocal = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const accessToken = urlSearchParams.get("token");
    accessToken && accessToken != null
        ? new Cookies().set(COOKIE_KEY, accessToken)
        : redirectToCommonLogin();
};

export const getToken = () => {
    const cookies = new Cookies();
    return cookies.get(COOKIE_KEY);
};

export const getBearerToken = () => {
    const cookies = new Cookies();
    return `Bearer ${cookies.get(COOKIE_KEY)}`;
};

export const logoutUser = () => {
    const cookies = new Cookies();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    localStorage.clear();
    cookies.remove(COOKIE_KEY);
    redirectToCommonLogin();
  };