import { getToken, isLocalhost, loginForLocal, redirectToCommonLogin } from "../../utility/CommonFunctions";

const Login = () => {

  if (isLocalhost()) { loginForLocal(); }

  const url = window.location.href;
  const currentDomain = url.split("?")[0];
  const cookieTokenData = getToken();

  if (cookieTokenData != null && cookieTokenData) {
    window.location.href = currentDomain;
  } else {
    redirectToCommonLogin();
  }
  return null;
};

export default Login;