import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function BasicSelect(props: any) {
  const dropDownList = props.dropDownList;
  const [selectedValue, setSelectedValue] = React.useState(dropDownList[0]);
  console.log(selectedValue, dropDownList);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value as string);
  };

  const dropDowns = dropDownList.map((dropdownValue: any, index: any) => 
  <MenuItem key={index} value={dropdownValue}>{dropdownValue}</MenuItem>);

  return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Apps</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedValue}
          label="Apps"
          onChange={handleChange}
          fullWidth
        >
          {dropDowns}
        </Select>
      </FormControl>
  );
}