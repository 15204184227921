const Environments = [
    {
        envId: 1,
        envName: 'UAT',
        envCode: 'UAT'
    },{
        envId: 2,
        envName: 'Pre-prod',
        envCode: 'Preprod'
    },{
        envId: 3,
        envName: 'Production',
        envCode: 'Prod'
    },
]

export default Environments;
