import BasicSelect from '../../components/dropDown';
import ScrollableTabsButtonAuto from '../../components/horizontalTabView';
import './UserDetails.scss';
import { useLocation } from 'react-router-dom';
import Environments from '../../datasets/Environments';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants/EnvConstants';
import { getToken } from '../../utility/CommonFunctions';

function UserDetails() {

  const token = getToken();

    const location = useLocation();
    let [apps, setApps] = useState([]);
    let roles: any = [];
    let env = Environments;
    let name = location.state.name;
    let userId = location.state.userId;

    useEffect(() => {
        // Fecthing app list
        fetch(`${API_BASE_URL}/app-list`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          method: "POST"
        })
        .then(
          (response) => response.json()
        )
        .then(
          (data) => {
            setApps(data.data);
          }
        );
      }, []);

    return (
        <div className="user-details-container">
            <div className='user-details-border'>
                <div style={{color: '#666666', display: 'flex', alignItems: 'center', padding: '10px',borderRadius: '40px', boxShadow: '0px 0px 18px 0px #d3dcfa'}}>{name}</div>
                <div style={{width: '12%'}}><BasicSelect dropDownList={apps} /></div>
            </div>
            <div className="h-tab-view">
                <ScrollableTabsButtonAuto htabList={env} vtabValue={roles} userId={userId} app={'lms'} />
            </div>
        </div>
    );
}

export default UserDetails;