import React from 'react';
import header_logo from '../assets/SynoriqLogo.bba84c8b32de2613a8a1.png';

const Footer = () => {
  return (
    <div className="Footer">
        <div style={{fontSize: 'small'}}>Powered by</div>
        <img src={header_logo} alt='footer_logo'></img>
      </div>
  )
}

export default Footer