import './App.scss';
import AppRouter from './routing/AppRouter';
import Footer from './components/Footer';
import Header from './components/Header';



function App() {

  
  return (
    <div className="App">
      <Header/>
      <AppRouter />
      <Footer/>
    </div>
  );
}

export default App;
