import {RouteObject} from 'react-router-dom';
import UserTable from '../features/UserTable/UserTable';
import UserDetails from '../features/UserDetails/UserDetails';
import ErrorPage from '../features/Error/Error';
import Login from '../features/Login/Login';

export const AUTHORIZED_ROUTES: RouteObject[] = [
    {
        path: "/",
        element: (
            <UserTable />
        )
        // , 
        // errorElement: (
        //     <ErrorPage />
        // )
    },
    {
        path: "/userTable",
        element: <UserTable />
    },
    {
        path: "/userDetails",
        element: <UserDetails />
    }
];

export const UNAUTHORIZED_ROUTES: RouteObject[] = [
    {
        path: "/",
        element: (
            <Login />
        )
    },
    {
        path: "/login",
        element: (
            <Login />
        )
    },
    {
        path: "/*",
        element: (
            <Login />
        )
    }
];