import React from 'react';
import { Alert, Button } from '@mui/material';
import header_logo from '../assets/SynoriqLogo.bba84c8b32de2613a8a1.png';
import { API_BASE_URL } from '../constants/EnvConstants';
import { getToken, logoutUser } from '../utility/CommonFunctions';

const token = getToken();

const Header = () => {
  const userSync = async () => {
    try {
        await (await fetch(`${API_BASE_URL}/user/sync-users`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          method: "POST"
        }).then(
          (response) => response.json()
        )
        .then(
          (data) => {
            console.log('user sync - ',data.data);
          }
        ));
    } catch (err: any) {
        console.log(err.message);
    }
  }

  const roleSync = async () => {
    try {
      await (await fetch(`${API_BASE_URL}/lms/sync-roles`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        method: "POST"
      }).then(
        (response) => response.json()
      )
      .then(
        (data) => {
          console.log('role sync - ',data.data);
          if(data.response) {
            return (
              <Alert severity="success">Roles are in sync now!</Alert>
            );
          }
        }
      ));
    } catch (err: any) {
        console.log(err.message);
    }
  }

  const userRoleSync = async () => {
    try {
      await (await fetch(`${API_BASE_URL}/lms/sync-user-roles`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        method: "POST"
      }).then(
        (response) => response.json()
      )
      .then(
        (data) => {
          console.log('user role sync - ',data.data);
          if(data.response) {
            return (
              <Alert severity="success">User-Roles are in sync now!</Alert>
            );
          }
        }
      ));
    } catch (err: any) {
        console.log(err.message);
    }
  }

  return (
    <div className="Header">
    <img src={header_logo} alt='header_logo'></img>
    <div style={{display: 'flex'}}>
      <div className="Button">
        <Button onClick={userSync}>Sync Users</Button>
      </div>
      <div className="Button">
        <Button onClick={roleSync}>Sync Roles</Button>
      </div>
      <div className="Button">
        <Button onClick={userRoleSync}>Sync User-Roles</Button>
      </div>
      <div className="Button">
        <Button onClick={logoutUser}>Logout</Button>
      </div>
    </div>
  </div>
  )
}

export default Header