import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import VerticalTabs from './verticalTabView';
import Environments from '../datasets/Environments';
import { useState } from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Env {
  envId: number;
  envName: string;
  envCode: string;
}

export default function ScrollableTabsButtonAuto(props: any) {
  const [value, setValue] = React.useState(1);
  const htabList = props.htabList;
  const vtabValue = props.vtabValue;
  const userId = props.userId;
  const app = props.app;
  let [env, setEnv] = React.useState<Env[]>(Environments);
  let [selectedEnv, setSelectedEnv] = useState('UAT');

  const tabs = env.map((tabValue: any, index: any) => {
    return (<Tab key={index} label={tabValue.envName} {...a11yProps(index)} style={{backgroundColor:'#d3dcfa', color: 'black'}} />)    
  });
  const customTabPanels = htabList.map((tabValue: any, index: any) => {
    return (
      <CustomTabPanel value={value} index={index}>
        <VerticalTabs tabValue={vtabValue} env={selectedEnv} userId={userId} app={app} />
      </CustomTabPanel>
    )
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelectedEnv(env[newValue].envCode);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ bgcolor: 'background.paper' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          // variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
          style={{backgroundColor:'#d3dcfa', color: 'black'}}
        >
          {tabs}
        
        </Tabs>
      </Box>   
      {customTabPanels}
    </Box> 
  );
}
