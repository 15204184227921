import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AUTHORIZED_ROUTES, UNAUTHORIZED_ROUTES } from "./routes";
import { getToken } from "../utility/CommonFunctions";


const AppRouter = () => {
  const isAuthorized = getToken();
  const ROUTES =
    isAuthorized !== undefined && isAuthorized !== null && isAuthorized !== ""
      ? AUTHORIZED_ROUTES
      : UNAUTHORIZED_ROUTES;

  const browserRouter = createBrowserRouter(ROUTES);
  return (
    <>
      <RouterProvider router={browserRouter} />
    </>
  );
};

export default AppRouter;
