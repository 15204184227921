export const ENVIRONMENT = process.env.REACT_APP_ENV || "uat";


export const COMMON_LOGIN_URL = process.env.REACT_APP_COMMON_LOGIN_URL;

export const COOKIE_KEY = `${ENVIRONMENT}_access_token`;

export const GATEWAY_BASE_URL = process.env.REACT_APP_GATEWAY_BASE_URL;

export const API_BASE_URL = `${GATEWAY_BASE_URL}/ldap-service`;

export const LOGIN_APP_ID = "ldap";

